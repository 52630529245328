import Image, { ImageLoader, ImageProps } from 'next/image';

const loader: ImageLoader = ({ src }) =>
  // Bypass /_next image cache and load imgproxy-hosted images directly from the origin.
  // At a later stage we can choose the correct image size by use case, right now we just request the given URL.
  src;
export function HuutokaupatImage(props: ImageProps) {
  return (
    <Image
      unoptimized
      loader={loader}
      {...props}
      alt={props.alt}
    />
  );
}
