import { Text } from '@mezzoforte/forge';
import { Bid } from '@/types/bid';
import { useSession } from '@/hooks/useSession';
import { isUsersBidSurpassed, isUsersBidWinning } from '@/util/bid';

interface EntryCardBidStatusProps {
  entryId: number;
  isCancelled: boolean;
  bids?: Bid[];
}

export function EntryCardBidStatus({ entryId, bids, isCancelled }: EntryCardBidStatusProps) {
  const { entryInfo } = useSession();
  const { data: userEntryInfo } = entryInfo(entryId);

  const [showBidStatus, statusColor, statusText] = (() => {
    if (!bids) return [false];
    if (isCancelled) return [false];

    if (!userEntryInfo?.isLoggedIn) return [false];

    const { bidderId } = userEntryInfo;
    if (isUsersBidWinning(bids, bidderId)) {
      return [true, 'success', 'Tarjouksesi on tällä hetkellä voittava!'];
    }
    if (isUsersBidSurpassed(bids, bidderId)) {
      return [true, 'danger', 'Tarjouksesi ylitettiin'];
    }
    return [false];
  })();

  return (
    showBidStatus && (
      <Text
        color={statusColor}
        fontSize="sm"
        fontWeight="bold"
        position="relative"
      >
        {statusText}
      </Text>
    )
  );
}
