import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export function getVatlessSelection() {
  const value = window.localStorage.getItem('showVatlessPrices');
  return value === 'true';
}

export function useVatlessSelection() {
  const selectedVatlessQuery = useQuery({ queryKey: ['vatless-selection'], queryFn: getVatlessSelection });

  useEffect(() => {
    if (selectedVatlessQuery.isFetching) {
      return;
    }

    if (selectedVatlessQuery.data !== undefined) {
      window.localStorage.setItem('showVatlessPrices', selectedVatlessQuery.data.toString());
    }
  });

  function toggleVatless(currentVatState: boolean) {
    window.localStorage.setItem('showVatlessPrices', (!currentVatState).toString());
    void selectedVatlessQuery.refetch();
  }

  return {
    showVatlessPrice: selectedVatlessQuery.data ?? false,
    toggleVatless,
  };
}
